$(() => {
  function toggleDropdown (e) {
    const _d = $(e.target).closest('.dropdown')
    const _m = $('.dropdown-menu', _d)
    setTimeout(function () {
      const shouldOpen = e.type !== 'click' && _d.is(':hover')
      _m.toggleClass('show', shouldOpen)
      _d.toggleClass('show', shouldOpen)
      $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen)
    }, e.type === 'mouseleave' ? 0 : 0)
  }

  function navbarScroll () {
    var navbar = $('#main-nav')
    if (document.body.scrollTop > 130 || document.documentElement.scrollTop > 130) {
      navbar.addClass('navbar--collapsed')
    } else {
      navbar.removeClass('navbar--collapsed')
    }
  }

  $('body')
    .on('mouseenter mouseleave', '.dropdown', toggleDropdown)
    .on('click', '.dropdown-menu a', toggleDropdown)

  window.onscroll = function () {
    navbarScroll()
  }

  //
  // If Emerson Concert Hall, add a button to view seating link
  //

  const vfsButton = $('<a class="btn btn-default btn-sm c-btn--vfs" href="https://schwartz.emory.edu/events-tickets/box-office-information/view-from-your-seat.html" target="_blank">View From Seat</a>')
  $('p.tn-event-detail__location:contains("Emerson Concert Hall")').append(vfsButton)

  //
  // Genre filter toggle
  //
  $('.tn-events-keyword-filter').prepend('<button id="c-toggle-filter" class="btn btn-primary">Filter By Genre<span class="glyphicon glyphicon-chevron-down"></span></button>')
  $('#c-toggle-filter').on('click', function (event) {
    $('#tn-events-keyword-filter').toggleClass('tn-events-keyword-filter--active')
  })
})
